<template>
    <div class="bg-black min-h-screen flex items-center justify-center text-white">
        <div class="w-[500px] p-6 rounded-lg shadow-lg bg-gradient-to-b from-gray-900 to-black">
            <!-- Title -->
            <h2 class="neon-text text-center text-2xl font-bold text-red-600 mb-6">โบนัสฝากครั้งแรก</h2>

            <!-- Bonus Table -->
            <div class="overflow-hidden rounded-lg border border-gray-700">
                <table class="w-full text-center text-sm">
                    <thead class="bg-red-700">
                        <tr>
                            <th class="py-2 px-4 text-white">ฝากครั้งแรก</th>
                            <th class="py-2 px-4 text-white">รับเพิ่ม</th>
                            <th class="py-2 px-4 text-white">เปอร์เซ็นต์</th>
                        </tr>
                    </thead>
                    <tbody class="bg-black">
                        <tr v-for="(bonus, index) in bonusData" :key="index" class="border-t border-gray-700">
                            <td class="py-2 px-4 text-gray-200">{{ bonus.deposit }}</td>
                            <td class="py-2 px-4 text-yellow-400">{{ bonus.reward }}</td>
                            <td class="py-2 px-4 text-green-400">{{ bonus.percentage }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Options -->
            <!-- Warning Section -->
            <div class="mt-6 bg-red-100 p-3 rounded-md flex items-center">
                <i class="fas fa-exclamation-circle text-red-600 text-xl mr-3"></i>
                <p class="text-gray-800 text-sm">
                    ข้อมูลโบนัส: เงื่อนไขได้รับสิทธิ์ 1 ครั้งต่อวันเท่านั้น เลือกยอดที่คุ้มที่สุดได้เลยค่ะ
                </p>
            </div>

            <!-- Action Button -->
            <button @click="goToDeposit"
                class="w-full mt-6 py-2 bg-red-600 hover:bg-red-700 rounded text-white font-bold text-lg">
                ไปหน้าฝากเงิน
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "BonusPage",
    data() {
        return {
            bonusData: [
                { deposit: "≥100", reward: "10.00", percentage: "10%" },
                { deposit: "≥500", reward: "100.00", percentage: "20%" },
                { deposit: "≥1,000", reward: "300.00", percentage: "30%" },
                { deposit: "≥5,000", reward: "2,000.00", percentage: "40%" },
                { deposit: "≥10,000", reward: "5,000.00", percentage: "50%" },
            ],
        };
    },
    methods: {
        goToDeposit() {
            this.$router.push("/deposit");
        },
    },
};
</script>

<style scoped>
.bg-gradient-to-b {
    background: linear-gradient(to bottom, #111, #000);
}

table th,
table td {
    padding: 0.75rem;
}

table tbody tr:hover {
    background-color: #1a1a1a;
    /* Slight hover effect */
}

.neon-text {
    text-shadow:
        0 0 5px #fff,
        0 0 10px #fff,
        0 0 20px #fff,
        0 0 30px #fff,
        0 0 40px #fff,
        0 0 50px #fff,
        0 0 75px #fff;
    animation: neonPulse 1.5s infinite alternate;
}

@keyframes neonPulse {
    0% {
        text-shadow:
            0 0 5px #fff,
            0 0 10px #fff,
            0 0 15px #fff,
            0 0 20px #ff0000,
            0 0 25px #ff0000,
            0 0 30px #ff0000,
            0 0 40px #ff0000;
    }

    100% {
        text-shadow:
            0 0 10px #fff,
            0 0 20px #fff,
            0 0 30px #fff,
            0 0 40px #ff0000,
            0 0 50px #ff0000,
            0 0 60px #ff0000,
            0 0 80px #ff0000;
    }
}
</style>
