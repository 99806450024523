<template>
    <div class="bg-black">
        <!-- Navbar -->
        <Navbar />

        <!-- Slider -->
        <div class="mb-8">
            <Slider />
        </div>

        <!-- Register Section -->
        <div class="flex justify-center items-center bg-black">
            <div class="bg-white w-[350px] md:w-[650px] p-4 rounded-lg shadow-lg">

                <div class="mb-6 -mt-4 text-center">
                    <!-- Big Title -->
                    <h2 class="bg-red-600 text-white text-xl font-bold py-2 rounded-t -mx-4 px-4">
                        สมัครสมาชิก
                    </h2>
                    <!-- Subtitle -->
                    <p class="text-center text-black text-sm mt-2">
                        เพื่อเข้าเล่นคาสิโนออนไลน์ ที่เว็บไซต์ UFAFAT.COM
                    </p>
                </div>

                <!-- Form -->
                <form @submit.prevent="handleRegister">
                    <!-- Phone Number -->
                    <div class="mb-4 flex items-center input-3d">
                        <span class="bg-gray-100 p-3 rounded-l">
                            <i class="fas fa-phone text-gray-500"></i>
                        </span>
                        <input type="text" v-model="phoneNumber" placeholder="เบอร์โทรศัพท์"
                            class="w-full p-3 rounded-r focus:outline-none" />
                    </div>

                    <!-- Password -->
                    <div class="mb-4 flex items-center input-3d">
                        <span class="bg-gray-100 p-3 rounded-l">
                            <i class="fas fa-lock text-gray-500"></i>
                        </span>
                        <input type="password" v-model="password" placeholder="ตั้งรหัสผ่าน"
                            class="w-full p-3 rounded-r focus:outline-none" />
                    </div>

                    <!-- Confirm Password -->
                    <div class="mb-4 flex items-center input-3d">
                        <span class="bg-gray-100 p-3 rounded-l">
                            <i class="fas fa-lock text-gray-500"></i>
                        </span>
                        <input type="password" v-model="confirmPassword" placeholder="ยืนยันรหัสผ่านเดิมอีกครั้ง"
                            class="w-full p-3 rounded-r focus:outline-none" />
                    </div>

                    <!-- Full Name -->
                    <div class="mb-4 flex items-center input-3d">
                        <span class="bg-gray-100 p-3 rounded-l">
                            <i class="fas fa-user text-gray-500"></i>
                        </span>
                        <input type="text" v-model="fullName" placeholder="ชื่อ นามสกุล"
                            class="w-full p-3 rounded-r focus:outline-none" />
                    </div>

                    <!-- Bank Name -->
                    <div class="mb-4 flex items-center input-3d relative">
                        <span class="bg-gray-100 p-3 rounded-l">
                            <i class="fas fa-university text-gray-500"></i>
                        </span>
                        <div class="relative w-full">
                            <button @click="toggleBankDropdown" type="button"
                                class="w-full p-3 rounded-r bg-white focus:outline-none flex items-center justify-between">
                                <span>{{ selectedBankName ? selectedBankName.label : 'เลือกบัญชีธนาคาร' }}</span>
                                <i class="fas fa-chevron-down"></i>
                            </button>
                            <ul v-if="showBankDropdown"
                                class="absolute bg-white shadow-lg rounded w-full mt-2 z-10 max-h-60 overflow-auto">
                                <li v-for="(bank, index) in bankOptions" :key="index" @click="selectBank(bank)"
                                    class="p-2 hover:bg-gray-200 cursor-pointer flex items-center">
                                    <img :src="bank.icon" alt="Bank Logo" class="mr-2 w-6 h-6 object-contain" />
                                    {{ bank.label }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Bank Account Number -->
                    <div class="mb-4 flex items-center input-3d">
                        <span class="bg-gray-100 p-3 rounded-l">
                            <i class="fas fa-credit-card text-gray-500"></i>
                        </span>
                        <input type="text" v-model="bankAccount" placeholder="เลขบัญชีธนาคาร"
                            class="w-full p-3 rounded-r focus:outline-none" />
                    </div>

                    <!-- Referral Code Display -->
                    <div class="mb-4 flex items-center input-3d">
                        <span class="bg-gray-100 p-3 rounded-l">
                            <i class="fas fa-globe text-gray-500"></i>
                        </span>
                        <div class="w-full p-3 rounded-r text-sm text-gray-700 bg-gray-100">
                           โค้ดแนะนำ: <strong>{{ referralCode }}</strong>
                        </div>
                    </div>


                    <!-- Referral -->
                    <!-- <div class="mb-6 flex items-center input-3d relative">
                        <span class="bg-gray-100 p-3 rounded-l">
                            <i class="fas fa-globe text-gray-500"></i>
                        </span>
                        <div class="relative w-full">
                            <button @click="toggleReferralDropdown" type="button"
                                class="w-full p-3 rounded-r bg-white focus:outline-none flex items-center justify-between">
                                <span>{{ selectedReferral ? selectedReferral.label : 'เลือกช่องทางที่รู้จักเรา'
                                    }}</span>
                                <i class="fas fa-chevron-down"></i>
                            </button>
                            <ul v-if="showReferralDropdown"
                                class="absolute bg-white shadow-lg rounded w-full mt-2 z-10 max-h-40 overflow-auto">
                                <li v-for="(ref, index) in referralOptions" :key="index" @click="selectReferral(ref)"
                                    class="p-2 hover:bg-gray-200 cursor-pointer flex items-center">
                                    <i :class="ref.icon" class="mr-2"></i>
                                    {{ ref.label }}
                                </li>
                            </ul>
                        </div>
                    </div> -->

                    <!-- Terms & Conditions -->
                    <p class="bg-red-900 text-xs text-white mb-4 p-3 rounded-l flex items-center">
                        <i class="fas fa-exclamation-triangle text-yellow-400 mr-2"></i>
                        ชื่อบัญชีฝาก ต้องตรงกับบัญชีถอน ต้องภาษาไทยเท่านั้น
                        ไม่เช่นนั้นจะถอนเงินไม่ได้
                    </p>

                    <!-- Submit Button -->
                    <button type="submit"
                        class="w-full bg-red-600 text-white py-3 rounded font-bold text-lg hover:bg-red-700 flex items-center justify-center">
                        <i class="fas fa-check-circle mr-2"></i> ยืนยันสมัคร
                    </button>
                </form>
            </div>
        </div>

        <!-- Footer -->
        <Footer />

        <!-- Bottom Menu -->
        <BottomMenu />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";
import BottomMenu from "@/components/BottomMenu.vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { ref } from "vue";

export default {
    name: "RegisterPage",
    components: {
        Navbar,
        Slider,
        Footer,
        BottomMenu,
    },
    setup() {
        const showBankDropdown = ref(false);
        const showReferralDropdown = ref(false);

        const bankOptions = [
            { label: "ธนาคารกสิกรไทย", icon: "/images/payments/payment1.png" },
            { label: "ธนาคารไทยพาณิชย์", icon: "/images/payments/payment2.png" },
            { label: "ธนาคารกรุงไทย", icon: "/images/payments/payment3.png" },
            { label: "ธนาคารกรุงเทพ", icon: "/images/payments/payment4.png" },
            { label: "ทรูมันนี่ วอลเล็ท", icon: "/images/payments/payment5.png" },
            { label: "ธนาคารออมสิน", icon: "/images/payments/payment6.png" },
            { label: "ธนาคารกรุงศรีอยุธยา", icon: "/images/payments/payment7.png" },
            { label: "ธนาคารธนชาติ", icon: "/images/payments/payment8.png" },
            { label: "ธนาคารเกียรตินาคินภัทร", icon: "/images/payments/payment9.png" },
            { label: "ธนาคารอาคารสงเคราะห์", icon: "/images/payments/payment10.png" },
            { label: "ธนาคารทีเอ็มบีธนชาต", icon: "/images/payments/payment11.png" },
            { label: "ธนาคารทิสโก้", icon: "/images/payments/payment12.png" },
            { label: "ธนาคารยูโอบี", icon: "/images/payments/payment13.png" },
            { label: "ธนาคารซิตี้แบงก์", icon: "/images/payments/payment14.png" },
            { label: "ธนาคารแลนด์แอนด์เฮ้าส์", icon: "/images/payments/payment15.png" },
            { label: "ธนาคารซีไอเอ็มบีไทย", icon: "/images/payments/payment17.png" },
            { label: "ธนาคารธนชาต", icon: "/images/payments/payment18.png" },
            { label: "ธนาคารมิซูโฮ", icon: "/images/payments/payment19.png" },
            { label: "ธนาคารสแตนดาร์ดชาร์เตอร์ด", icon: "/images/payments/payment20.png" },
        ];


        const referralOptions = [
            { label: "Google", icon: "fab fa-google" },
            { label: "Facebook", icon: "fab fa-facebook" },
            { label: "เว็บไซต์ติดแบนเนอร์", icon: "fas fa-ad" },
            { label: "เพื่อนแนะนำ", icon: "fas fa-user-friends" },
        ];

        const selectedBankName = ref(null);
        const selectedReferral = ref(null);

        const phoneNumber = ref("");
        const password = ref("");
        const confirmPassword = ref("");
        const fullName = ref("");
        const bankAccount = ref("");
        const referralCode = ref(new URLSearchParams(window.location.search).get("referral"));

        const toast = useToast();
        const router = useRouter();

        const toggleBankDropdown = () => {
            showBankDropdown.value = !showBankDropdown.value;
        };

        const toggleReferralDropdown = () => {
            showReferralDropdown.value = !showReferralDropdown.value;
        };

        const selectBank = (bank) => {
            selectedBankName.value = bank;
            showBankDropdown.value = false;
        };

        const selectReferral = (ref) => {
            selectedReferral.value = ref;
            showReferralDropdown.value = false;
        };

        const handleRegister = () => {
            toast.success("สมัครสมาชิกสำเร็จ!");
            router.push("/playgame");
        };

        return {
            phoneNumber,
            password,
            confirmPassword,
            fullName,
            bankAccount,
            referralCode,
            showBankDropdown,
            showReferralDropdown,
            bankOptions,
            referralOptions,
            selectedBankName,
            selectedReferral,
            toggleBankDropdown,
            toggleReferralDropdown,
            selectBank,
            selectReferral,
            handleRegister,
        };
    },
};
</script>

<style scoped>
.input-3d {
    border: 1px solid #ccc;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2), inset -2px -2px 5px rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li:hover {
    background-color: #f5f5f5;
}
</style>
