const isLocalhost = window.location.hostname === 'localhost';
const BASE_URL = isLocalhost
  ? 'http://localhost:5007'
  : process.env.VUE_APP_API_BASE;

export const API_PATHS = {
  GET_SALEPAGE_BY_SLUG: (slug) => `${BASE_URL}/api/salepage/by-slug/${slug}`,

  // ✅ NEW: สำหรับ Stats
  TRACK_VIEW: (tenantId) => `${BASE_URL}/api/stats/${tenantId}/track-view`,
  TRACK_CLICK: (tenantId) => `${BASE_URL}/api/stats/${tenantId}/track-click`
};
