<template>
    <div class=" max-w-[700px] mx-auto bg-gradient-to-b from-black via-gray-900 to-black text-white">
        <!-- Desktop-Specific Layout Container -->
        <div :class="isDesktop ? 'max-w-[700px] mx-auto' : ''">
            <!-- Left Menu for Desktop Mode -->
            <div v-if="isDesktop" class="flex p-2 justify-center mb-6">
                <ul class="flex space-x-4 max-w-[700px] mx-auto">
                    <li v-for="(menu, index) in menus" :key="index" @click="selectMenu(index)" :class="[
                        'flex items-center justify-start p-3 cursor-pointer rounded-md transition space-x-3',
                        selectedMenu === index ? 'bg-red-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                    ]" class="w-56 h-12 flex items-center rounded-md">
                        <img :src="menu.icon" alt="menu-icon" class="w-8 h-8" />
                        <span class="text-sm font-bold text-center">{{ menu.label }}</span>
                    </li>
                </ul>
            </div>


            <!-- Top Section: Slide Indicators + Image Slider -->
            <div v-if="selectedMenu === 3" class="relative">
                <div class="relative overflow-x-auto border border-yellow-600 rounded-lg mb-4 p-2 ml-2 mr-2">
                    <div class="flex space-x-2 items-center justify-start w-max">
                        <div v-for="(slide, index) in slides" :key="index"
                            class="w-20 h-10 bg-gray-800 flex items-center justify-center rounded-lg cursor-pointer flex-shrink-0"
                            :class="{ 'bg-red-600': activeSlideIndex === index }" @click="selectSlide(index)">
                            <img :src="slide.image" alt="slide" class="w-full h-full object-contain rounded" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Main Content Section -->
            <div class="flex flex-grow">
                <!-- Left Menu for Mobile Mode -->
                <div v-if="!isDesktop" class="w-1/4 bg-black border-r-2 border-red-600 mt-2">
                    <ul class="space-y-1 p-1 pt-0">  
                        <li v-for="(menu, index) in menus" :key="index" @click="selectMenu(index)" :class="[
                            'flex flex-col items-center justify-center p-3 cursor-pointer rounded-md transition',
                            selectedMenu === index ? 'bg-red-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                        ]">
                            <div class="flex flex-col items-center justify-center w-20 h-12 rounded-md">
                                <img :src="menu.icon" alt="menu-icon" class="w-10 h-10 mb-1" />
                                <span class="text-sm font-bold text-center">{{ menu.label }}</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- Right Content -->
                <div class="w-full p-1">
                    <!-- Selected Image Display -->
                    <div v-if="selectedMenu === 3 && selectedSlideImage" class="mb-4">
                        <img :src="selectedSlideImage" alt="Selected Slide"
                            class="w-20 h-10 object-cover rounded shadow-lg ml-2" />
                    </div>

                    <!-- Dynamic Content -->
                    <div :class="isDesktop ? 'grid grid-cols-6 gap-2' : 'grid grid-cols-3 gap-1'">
                        <div v-for="(item, index) in menus[selectedMenu].content" :key="index"
                            class="p-1 rounded-lg flex flex-col items-center justify-center shadow">
                            <img :src="item.image" alt="game" class="w-full h-auto object-cover rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GamelistApp",
    data() {
        return {
            selectedMenu: 0,
            activeSlideIndex: 0,
            selectedSlideImage: null,
            isDesktop: window.innerWidth >= 768,
            menuCategories: {
                favorite: {
                    label: "เกมโปรด",
                    icon: "/images/icons/favorite.webp",
                    content: Array.from({ length: 30 }, (_, index) => ({
                        image: `/images/Slot-images/${index + 1}.JPEG.png`,
                    })),
                },
                hit: {
                    label: "เกมฮิต",
                    icon: "/images/icons/gamehits.webp",
                    content: Array.from({ length: 30 }, (_, index) => ({
                        image: `/images/Slot-images/${index + 1}.JPEG.png`,
                    })),
                },
                new: {
                    label: "เกมใหม่",
                    icon: "/images/icons/gamenew.webp",
                    content: Array.from({ length: 30 }, (_, index) => ({
                        image: `/images/Slot-images/${index + 1}.JPEG.png`,
                    })),
                },
                slot: {
                    label: "สล็อต",
                    icon: "/images/icons/slot.png",
                    content: Array.from({ length: 30 }, (_, index) => ({
                        image: `/images/Slot-images/${index + 1}.JPEG.png`,
                    })),
                },
               casino: {
                    label: "คาสิโน",
                    icon: "/images/icons/casino.png",
                    content: Array.from({ length: 30 }, (_, index) => ({
                        image: `/images/Slot-images/${index + 1}.JPEG.png`,
                    })),
                },
                sport: {
                    label: "กีฬา",
                    icon: "/images/icons/sport.png",
                    content: Array.from({ length: 30 }, (_, index) => ({
                        image: `/images/Slot-images/${index + 1}.JPEG.png`,
                    })),
                },
            },
            slides: Array.from({ length: 16 }, (_, i) => ({
                image: `/images/partners/casino${i + 1}.webp`,
            })),
        };
    },
    computed: {
        menus() {
            return Object.values(this.menuCategories);
        },
        activeSlide() {
            return this.slides[this.activeSlideIndex];
        },
    },
    methods: {
        selectMenu(index) {
            this.selectedMenu = index;
            this.activeSlideIndex = 0;
            this.selectedSlideImage = null;
        },
        selectSlide(index) {
            this.activeSlideIndex = index;
            this.selectedSlideImage = this.slides[index].image;
        },
        handleResize() {
            this.isDesktop = window.innerWidth >= 768;
        },
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
};
</script>

<style scoped>
.bg-gradient-to-b {
    background: linear-gradient(to bottom, #000000, #1f1f1f, #000000);
}

.max-w-screen-lg {
    max-width: 1024px;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.px-24 {
    padding-left: 100px;
    padding-right: 100px;
}
</style>
