<template>
    <div class=" bg-black text-white max-w-[700px] mx-auto md:px-16">
        <!-- Navbar -->
        <NavbarLogin />

        <div class="flex-1 p-4">
            <!-- Top Section: Points and Redeem -->
            <div class="bg-red-600 p-4 rounded-lg flex flex-col mb-4">
                <div class="flex justify-between items-center">
                    <div>
                        <h1 class="text-2xl font-bold">คะแนนสะสม</h1>
                        <p class="text-4xl font-extrabold">{{ points }}
                            <i class="fas fa-money-bill-wave text-yellow-400 text-3xl mr-4"></i>
                        </p>
                    </div>
                    <button @click="redeem" :disabled="points < 100"
                        class="bg-black text-white border border-white px-6 py-2 rounded-lg shadow-lg hover:bg-red-700"
                        :class="{ 'opacity-50 cursor-not-allowed': points < 100 }">
                        แลกเครดิต
                    </button>
                </div>
                <!-- Warning Message -->
                <p class="mt-2 text-sm text-yellow-300 font-bold">
                    ต้องมีคะแนนสะสมอย่างน้อย 100 เครติด (1 เครดิต = 1 บาท) เพื่อแลกเครดิต
                </p>
            </div>


            <!-- 7 Days Points Section -->
            <div class="bg-gray-800 p-4 rounded-lg mb-4">
                <h2 class="text-xl font-bold mb-4">สะสมคะแนน 7 วัน</h2>
                <div class="grid grid-cols-4 gap-4">
                    <div v-for="(reward, index) in weekRewards" :key="index"
                        class="flex flex-col items-center justify-center text-center p-2 rounded-lg"
                        :class="{ 'bg-yellow-400 text-white': reward.collected, 'bg-gray-500': !reward.collected }">
                        <h3 class="text-sm font-bold">
                            {{ reward.collected ? 'เก็บแล้ว' : `วันที่ ${reward.day}` }}
                        </h3>
                        <img v-if="!reward.collected" src="/images/icons/login-icon.png" alt="coin icon"
                            class="w-8 h-8 " />
                        <i v-if="reward.collected" class="fas fa-check-circle text-green-500 text-lg"></i>
                        <span class="text-lg font-bold">{{ reward.points }}</span>
                    </div>
                </div>
                <button @click="collectWeeklyPoints"
                    class="mt-4 bg-red-600 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-red-700">
                    เก็บคะแนน
                </button>
            </div>




            <!-- Task List Section -->
            <div class="bg-gray-800 p-4 rounded-lg mb-20">
                <h2 class="text-xl font-bold mb-4">รายการภารกิจ</h2>

                <!-- Task List -->
                <ul class="space-y-4">
                    <li v-for="(task, index) in tasks" :key="index" class="bg-gray-900 p-4 rounded-lg shadow-md">
                        <div class="flex flex-wrap justify-between items-center">
                            <!-- Task Name -->
                            <div class="flex-1 min-w-0">
                                <h3 class="font-bold text-md break-words">{{ task.name }}</h3>
                                <p class="text-sm text-yellow-400">+{{ task.points }} เครดิต</p>
                            </div>
                            <!-- Task Button -->
                            <button v-if="!task.completed" @click="$router.push(task.path)"
                                class="px-4 py-2 rounded-lg ml-4 bg-red-600 text-white hover:bg-red-700">
                                ทำเลย
                            </button>
                            <button v-else disabled class="px-4 py-2 rounded-lg ml-4 bg-gray-600 text-gray-400">
                                ทำสำเร็จแล้ว
                            </button>

                        </div>

                        <!-- Individual Task Progress -->
                        <div class="mt-2">
                            <div class="flex justify-between items-center mb-1">
                                <p class="text-xs font-bold text-gray-400">
                                    ความคืบหน้า: {{ task.progress }} / {{ task.total }}
                                </p>
                            </div>
                            <div class="w-full bg-gray-800 rounded-full h-2">
                                <div class="bg-yellow-400 h-2 rounded-full"
                                    :style="{ width: (task.progress / task.total) * 100 + '%' }"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Bottom Menu -->
        <BottomMenuLogin />
    </div>
</template>

<script>
import NavbarLogin from "@/components/NavbarLogin.vue";
import BottomMenuLogin from "@/components/BottomMenuLogin.vue";

export default {
    name: "TaskMission",
    components: {
        NavbarLogin,
        BottomMenuLogin,
    },
    computed: {
        tasksCompleted() {
            return this.tasks.filter(task => task.completed).length;
        },
        progressPercentage() {
            return Math.round((this.tasksCompleted / this.tasks.length) * 100);
        },
    },
    data() {
        return {
            points: 0,
            weekRewards: [
                { day: "01", points: 5, collected: false },
                { day: "02", points: 10, collected: false },
                { day: "03", points: 15, collected: false },
                { day: "04", points: 20, collected: false },
                { day: "05", points: 30, collected: false },
                { day: "06", points: 50, collected: false },
                { day: "07", points: 100, collected: false },
            ],
            tasks: [
                {
                    name: "ฝากแรก 100 บาท",
                    points: 20,
                    completed: false,
                    progress: 0,
                    total: 1,
                    path: "/deposit",
                },
                {
                    name: "เล่นเกมสล็อตค่าย PG 5 เกม",
                    points: 10,
                    completed: false,
                    progress: 0,
                    total: 5,
                    path: "/playgame",
                },
                {
                    name: "แทงบาคาร่า 5 ครั้ง ชนะ",
                    points: 15,
                    completed: false,
                    progress: 0,
                    total: 5,
                    path: "/playgame",
                },
                {
                    name: "เล่นเกมสล็อตค่าย Nolimit 5 เกม",
                    points: 10,
                    completed: false,
                    progress: 0,
                    total: 5,
                    path: "/playgame",
                },
                {
                    name: "ชวนเพื่อนมาสมัค 5 คน",
                    points: 50,
                    completed: false,
                    progress: 0,
                    total: 5,
                    path: "/refer-friends",
                },
            ],
        };
    },
    methods: {
        redeem() {
            alert("คุณได้แลกเครดิตสำเร็จ!");
        },
        collectWeeklyPoints() {
            this.weekRewards.forEach((reward) => {
                if (!reward.collected) {
                    this.points += reward.points;
                    reward.collected = true;
                }
            });
        },
        completeTask(index) {
            const task = this.tasks[index];
            if (!task.completed) {
                task.progress += 1;
                if (task.progress >= task.total) {
                    task.completed = true;
                    this.points += task.points;
                }
            }
        },
    },
};
</script>

<style scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}
</style>