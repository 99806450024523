<template>
  <div class="bg-black p-2 text-white max-w-[700px] mx-auto">
    <!-- Menu Section -->
    <div class="grid grid-cols-4 md:grid-cols-3 lg:grid-cols-3 gap-4">
      <router-link
        v-for="(menu, index) in menuItems"
        :key="index"
        :to="menu.path"
        class="flex flex-col items-center justify-center bg-black border border-gray-700 rounded-lg shadow-md p-1 hover:bg-gray-800"
      >
        <i :class="menu.icon" class="text-xl mb-2"></i>
        <p class="text-sm font-bold text-center">{{ menu.label }}</p>
      </router-link>
    </div>

    <!-- Announcement Section -->
    <!-- <div class="bg-red-600 mt-4 p-2 rounded-lg flex items-center justify-between">
      <p class="text-xs text-white flex-1 mx-2 text-center">
        UFAMAXBET88 ⚡ ฝากครั้งแรกรับเพี่ม 50% ขั้นต่ำ 2,000บ
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "MenuApp",
  data() {
    return {
      menuItems: [
        { label: "ข้อมูลบัญชี", icon: "fas fa-user", path: "/account-info" },
        { label: "ภารกิจรับเครดิต", icon: "fas fa-list", path: "/task-mission" },
        { label: "โบนัสฝากแรกรับเพี่ม", icon: "fas fa-star", path: "/bonus" },
        { label: "แจ็คพอตแตกรายวัน", icon: "fas fa-trophy", path: "/jackpot" },
        { label: "แข่งกับเพื่อนรับเครดิตเพี่ม", icon: "fas fa-trophy", path: "/tournament" },
      ],
    };
  },
};
</script>

<style scoped>
/* Styling for the menu container and cards */
.grid {
  gap: 0.5rem; /* Spacing between grid items */
}
.flex {
  transition: transform 0.3s, background-color 0.3s;
}
.flex:hover {
  transform: translateY(-1px);
}

/* Announcement Section Styling */
.bg-purple-900 {
  background-color: #4c1d95; /* Purple tone for announcement background */
}
</style>
