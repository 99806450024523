<template>
    <div class="bg-black">
        <!-- Navbar -->
        <NavbarLogin />

        <!-- Main Content Section -->
        <div class="flex flex-col items-center justify-center text-white">
            <!-- User Details -->
            <div class="bg-black border-2 border-red-600 rounded-lg p-3 shadow-lg text-center w-[350px] md:w-[400px]">
                <!-- User ID -->
                <p class="text-lg text-gray-300">
                    สวัสดี: <span class="font-bold text-white">ufnblff87080</span>
                </p>

                <!-- Credit Card -->
                <div class="bg-gradient-to-b from-gray-800 to-gray-900 rounded-md mt-2 p-4 shadow-md">
                    <p class="text-sm text-gray-300">ยอดเครดิตของคุณ</p>
                    <div class="flex items-center justify-center mt-2 space-x-4">
                        <h2 class="text-3xl font-bold text-white">2,500</h2>
                        <button class="text-white text-lg hover:text-gray-300" @click="refreshCredit">
                            <i class="fas fa-sync-alt"></i>
                        </button>
                    </div>

                    <!-- Buttons -->
                    <div class="flex mt-4 gap-4">
                        <button
                            class="flex-1 bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 rounded shadow"
                            @click="navigateTo('/deposit')">
                            <i class="fas fa-wallet mr-2"></i>ฝากเงิน
                        </button>
                        <button
                            class="flex-1 bg-green-400 hover:bg-green-500 text-black font-bold py-2 rounded shadow"
                            @click="navigateTo('/withdraw')">
                            <i class="fas fa-hand-holding-usd mr-2"></i>ถอนเงิน
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <Menu />
        <Gamelist />

        <!-- Footer -->
        <Footer />

        <!-- Bottom Menu for Logged-in Users -->
        <BottomMenuLogin />

        <!-- Modal Bonus -->
        <ModalBonus v-if="showModal" :isVisible="showModal" @close="showModal = false" />
    </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import BottomMenuLogin from "@/components/BottomMenuLogin.vue";
import Menu from "@/components/Menu.vue";
import Gamelist from "@/components/Gamelist.vue";
// import { useRouter } from "vue-router";
import NavbarLogin from "@/components/NavbarLogin.vue";
import ModalBonus from "@/components/ModalBonus.vue";

export default {
    name: "HomeLogin",
    components: {
        NavbarLogin,
        Footer,
        BottomMenuLogin,
        Menu,
        Gamelist,
        ModalBonus,
    },
    data() {
        return {
            showModal: false, // Initially hidden
        };
    },
    methods: {
        navigateTo(path) {
            this.$router.push(path);
        },
        refreshCredit() {
            console.log("Refreshing credit...");
            // Add logic for refreshing credit if needed
        },
        checkModalDisplay() {
            const dontShowBonusModal = localStorage.getItem("dontShowBonusModal");
            if (dontShowBonusModal !== "true") {
                this.showModal = true;
            }
        },
    },
    mounted() {
        this.checkModalDisplay(); // Check if modal should be displayed
    },
};
</script>

<style scoped>
/* Additional styling for the card */
.card {
    background: linear-gradient(to bottom, #111, #222);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
