<template>
    <footer class="max-w-[700px] mx-auto bg-black text-white py-6 px-4 md:px-16 mb-16 ">
      <!-- Logo Section -->
      <div class="flex justify-center mb-4">
        <img src="/logo/logo-ufafat.png" alt="UFAFAT Logo" class="h-16 w-auto" />
      </div>
  
      <!-- Text Section -->
      <div class="text-center text-sm md:text-base leading-relaxed mb-4">
        <p class="mb-2">
          บริการทางเข้าเล่น กีฬาออนไลน์ คาสิโนออนไลน์ โดยให้บริการตลอด 
          <span class="text-red-600 font-bold">24 ชั่วโมง</span> 
          ท่านสามารถ ฝาก และถอนเงินได้ตลอดเวลาที่ต้องการ
        </p>
        <p>
          เราบริการทางเข้าที่จับไวผ่านหน้าเว็บไซต์ 
          <span class="text-red-600 font-bold">UFABET</span>
          ที่มั่นคงที่สุดในประเทศไทย รับเดิมพันกีฬาออนไลน์และคาสิโนทุกประเภท
          ที่เป็นที่นิยมใช้บริการ มากที่สุดในประเทศไทย รับเดิมพันกีฬาออนไลน์และคาสิโนทุกประเภท บริการลูกค้าทุกวัน 24 ชั่วโมง ไม่มีวันหยุด
        </p>
      </div>
  
  
      <!-- Social Media and Contact -->
      <div class="flex flex-col items-center gap-4">
        <p class="text-sm text-gray-300">
          แอดไลน์: <span class="text-red-600 font-bold">@UFAFAT</span>
        </p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "FooterApp",
  };
  </script>
  
  <style scoped>
  footer {
    background-color: #000;
  }
  footer a {
    transition: color 0.3s ease;
  }
  </style>
  