import { createRouter, createWebHistory } from 'vue-router';

// Import pages
import Home from '@/pages/Home.vue';
import Login from '@/pages/Login.vue'; // Login page
import Register from '@/pages/Register.vue'; // Register page
import HomeLogin from '@/pages/HomeLogin.vue';
import Deposit from '@/pages/Deposit.vue';
import Withdraw from '@/pages/Withdraw.vue';
import AccountInfo from '@/pages/AccountInfo.vue';
import History from '@/pages/History.vue';
import ReferFriends from '@/pages/ReferFriends.vue';
import Bonus from '@/pages/Bonus.vue';
import Refund from '@/pages/Refund.vue';
import TaskMission from '@/pages/TaskMission.vue';
import MegaJackpot from '@/pages/MegaJackpot.vue';
import TournamentFight from '@/pages/TournamentFight.vue';

const routes = [
    { path: '/:slug', name: 'Home', component: Home }, // Default route
    { path: '/login', name: 'LoginPage', component: Login },
    { path: '/register', name: 'RegisterPage', component: Register },
    { path: '/playgame', name: 'HomeLogin', component: HomeLogin },
    { path: '/deposit', name: 'Deposit', component: Deposit },
    { path: '/withdraw', name: 'Withdraw', component: Withdraw },
    { path: '/account-info', name: 'AccountInfo', component: AccountInfo },
    { path: '/history', name: 'HistoryPage', component: History },
    { path: '/refer-friends', name: 'ReferFriends', component: ReferFriends },
    { path: '/bonus', name: 'BonusPage', component: Bonus },
    { path: '/refund', name: 'Refund', component: Refund },
    { path: '/task-mission', name: 'TaskMission', component: TaskMission },
    { path: '/jackpot', name: 'MegaJackpot', component: MegaJackpot },
    { path: '/tournament', name: 'TournamentFight', component: TournamentFight },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
