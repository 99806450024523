<template>
    <div class="bg-black ">
        <!-- Navbar -->
        <NavbarLogin />

        <!-- Slider -->
        <div class="mb-2">
            <Slider />
        </div>

        <!-- Withdraw Section -->
        <div class="flex justify-center items-center py-10">
            <div class="bg-white w-[380px] ml-3 mr-3 md:w-[450px] p-4 rounded-lg shadow-lg">
                <!-- Title -->
                <div class="text-center mb-6">
                    <div class="bg-black text-yellow-400 text-2xl font-bold py-4 rounded">
                        <!-- Title with Icon -->
                        <div class="flex items-center justify-center mb-2">
                            <i class="fas fa-wallet text-white mr-2"></i>
                            <h2 class="text-white text-xl font-bold">ยอดเงินคงเหลือ</h2>
                        </div>
                        <!-- Balance with Refresh Icon -->
                        <div class="flex items-center justify-center">
                            <span class="text-2xl font-bold">2,500</span>
                            <button @click="refreshBalance" class="ml-2 text-white hover:text-gray-300">
                                <i class="fas fa-sync-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Withdraw Form -->
                <form @submit.prevent="handleWithdraw">
                    <!-- Account Name -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2">ชื่อบัญชี</label>
                        <input type="text" v-model="accountName"
                            class="w-full p-3 rounded bg-gray-100 focus:outline-none" disabled />
                    </div>

                    <!-- Account Number -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2">โอนเข้าบัญชี</label>
                        <input type="text" v-model="accountNumber"
                            class="w-full p-3 rounded bg-gray-100 focus:outline-none" disabled />
                    </div>

                    <!-- Amount -->
                    <div class="mb-6">
                        <label class="block text-gray-700 text-sm font-bold mb-2">จำนวน</label>
                        <input type="text" v-model="withdrawAmount" placeholder="300 หรือ 5000 ไม่ต้องใส่ , (ลูกน้ำ)!"
                            class="w-full p-3 rounded bg-gray-100 focus:outline-none" />
                    </div>

                    <!-- Submit Button -->
                    <button type="submit"
                        class="w-full bg-red-600 hover:bg-red-700 text-white py-3 rounded font-bold flex items-center justify-center">
                        <i class="fas fa-hand-holding-usd mr-2"></i> แจ้งถอนเงิน
                    </button>
                </form>
            </div>
        </div>

        <!-- Footer -->
        <Footer />
        <BottomMenuLogin />
    </div>
</template>

<script>
import NavbarLogin from "@/components/NavbarLogin.vue";
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";
import BottomMenuLogin from "@/components/BottomMenuLogin.vue";
import { useToast } from "vue-toastification";
import { ref } from "vue";

export default {
    name: "WithdrawPage",
    components: {
        NavbarLogin,
        Slider,
        Footer,
        BottomMenuLogin,
    },
    setup() {
        const accountName = ref("นาย ชวนดี ตีสรักมิติ"); // Example data
        const accountNumber = ref("4912020172"); // Example data
        const withdrawAmount = ref(""); // User input for withdrawal amount
        const toast = useToast();

        const refreshBalance = () => {
            toast.success("รีเฟรชยอดเงินสำเร็จ!");
            // Add logic to refresh balance here
        };

        const handleWithdraw = () => {
            if (!withdrawAmount.value || isNaN(withdrawAmount.value)) {
                toast.error("กรุณาใส่จำนวนเงินที่ถูกต้อง");
                return;
            }

            toast.success("การแจ้งถอนเงินสำเร็จ!");
            // Add API call or logic to process withdrawal here
        };

        return {
            accountName,
            accountNumber,
            withdrawAmount,
            refreshBalance,
            handleWithdraw,
        };
    },
};
</script>

<style scoped>
.bg-black {
    background-color: #000000;
    /* กำหนดเป็น HEX */
}

input:disabled {
    background-color: #e5e7eb;
    /* สี Tailwind Gray-200 */
    color: #161515;
    /* สี Tailwind Gray-500 */
    cursor: not-allowed;
}

input::placeholder {
    color: #4a4a4a;
    /* กำหนดสี placeholder เทาดำ */
    opacity: 1;
    /* ทำให้สี placeholder ชัดเจน */
}

input:focus::placeholder {
    color: #6b6b6b;
    /* กำหนดสี placeholder เมื่อโฟกัส */
}

.text-yellow-400 {
    color: #ffc107;
    /* กำหนดสีเหลืองชัดเจน */
}

.bg-red-600 {
    background-color: #dc2626;
    /* สีแดง Tailwind */
}

.bg-red-700:hover {
    background-color: #b91c1c;
    /* สีแดงเข้มสำหรับ hover */
}

.bg-gray-100 {
    background-color: #f3f4f6;
    /* Tailwind Gray-100 */
}

.text-black {
    color: #000000;
    /* สีดำมาตรฐาน */
}
</style>