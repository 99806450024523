<template>
    <div class="max-w-[700px] mx-auto relative overflow-hidden w-full">
      <!-- Slider Images -->
      <div
        class="flex transition-transform duration-700 ease-in-out"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      >
        <div
          v-for="(image, index) in images"
          :key="index"
          class="flex-shrink-0 w-full h-full flex items-center justify-center"
        >
          <img
            :src="image"
            alt="Slider Image"
            class="w-full h-auto object-contain"
          />
        </div>
      </div>
  
      <!-- Navigation Dots -->
      <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        <button
          v-for="(image, index) in images"
          :key="index"
          :class="[ 
            'w-3 h-3 rounded-full border-2',
            currentIndex === index ? 'bg-red-600 border-red-600' : 'bg-gray-400 border-gray-400',
          ]"
          @click="goToSlide(index)"
        ></button>
      </div>
  
      <!-- Previous Button -->
      <button
        class="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-2 py-1 rounded"
        @click="prevSlide"
      >
        ‹
      </button>
  
      <!-- Next Button -->
      <button
        class="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-2 py-1 rounded"
        @click="nextSlide"
      >
        ›
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: "SliderApp",
    data() {
      return {
        currentIndex: 0,
        images: [
          "/images/slider/slider1.jpg", // Replace with your images
          "/images/slider/slider1.jpg",
          "/images/slider/slider1.jpg",
          "/images/slider/slider1.jpg",
        ],
        intervalId: null, // For auto-sliding
      };
    },
    methods: {
      nextSlide() {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      },
      prevSlide() {
        this.currentIndex =
          (this.currentIndex - 1 + this.images.length) % this.images.length;
      },
      goToSlide(index) {
        this.currentIndex = index;
      },
      startAutoSlide() {
        this.intervalId = setInterval(() => {
          this.nextSlide();
        }, 3000); // Slides every 5 seconds
      },
      stopAutoSlide() {
        clearInterval(this.intervalId);
      },
    },
    mounted() {
      this.startAutoSlide();
  
      // Pause auto-slide on hover
      const sliderContainer = document.querySelector(".relative");
      sliderContainer.addEventListener("mouseenter", this.stopAutoSlide);
      sliderContainer.addEventListener("mouseleave", this.startAutoSlide);
    },
    beforeUnmount() {
      this.stopAutoSlide();
    },
  };
  </script>
  
  <style>
  /* Add any custom styling if necessary */
  </style>
  