<template>
  <div class="bg-black">
    <!-- Navbar -->
    <Navbar />

    <!-- Slider -->
    <div class="mb-8">
      <Slider />
    </div>

    <!-- Login Section -->
    <div class="flex justify-center items-center bg-black">
      <div class="bg-white w-[350px] md:w-[650px] p-4 rounded-lg shadow-lg">
        <div class="mb-6 -mt-4 text-center">
          <h2 class="bg-red-600 text-white text-xl font-bold py-2 rounded-t -mx-4 px-4">เข้าสู่ระบบ</h2>
          <p class="text-center text-black text-sm mt-2">
            เพื่อเข้าเล่นคาสิโนออนไลน์ ที่เว็บไซต์ UFAFAT.COM
          </p>
        </div>

        <form @submit.prevent="handleLogin">
          <div class="mb-4 flex items-center input-3d">
            <span class="bg-gray-100 p-3 rounded-l">
              <i class="fas fa-phone text-gray-500"></i>
            </span>
            <input
              type="text"
              v-model="phoneNumber"
              placeholder="กรอกเบอร์โทรศัพท์"
              class="w-full p-3 rounded-r focus:outline-none"
            />
          </div>
          <div class="mb-6 flex items-center input-3d">
            <span class="bg-gray-100 p-3 rounded-l">
              <i class="fas fa-lock text-gray-500"></i>
            </span>
            <input
              type="password"
              v-model="password"
              placeholder="กรอกรหัสผ่าน"
              class="w-full p-3 rounded-r focus:outline-none"
            />
          </div>
          <button
            type="submit"
            class="w-full bg-red-600 text-white py-3 rounded font-bold text-lg hover:bg-red-700 flex items-center justify-center"
          >
            <i class="fas fa-sign-in-alt mr-2"></i> เข้าสู่ระบบ
          </button>
        </form>
      </div>
    </div>

    <!-- Footer -->
    <Footer />
    <BottomMenu />
    
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";
import BottomMenu from "@/components/BottomMenu.vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

export default {
  name: "LoginPage",
  components: {
    Navbar,
    Slider,
    Footer,
    BottomMenu,
  },
  data() {
    return {
      phoneNumber: "",
      password: "",
    };
  },
  setup() {
    const toast = useToast();
    const router = useRouter();

    const handleLogin = () => {
      // Show toast message
      toast.success("เข้าสู่ระบบสำเร็จ!");

      // Redirect to /playgame
      router.push("/playgame");
    };

    return {
      handleLogin,
    };
  },
};
</script>

<style scoped>
.bg-black {
  background-color: #000;
}

.input-3d {
  border: 1px solid #ccc;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2), inset -2px -2px 5px rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  overflow: hidden;
}

.input-3d input {
  border: none;
  padding: 10px;
  width: 100%;
  background: transparent;
}

.input-3d input:focus {
  outline: none;
}
</style>
