<template>
  <nav class=" max-w-[700px] mx-auto bg-black text-white p-4 flex items-center justify-between border-b-2 border-red-800 mb-5">
    <!-- Toggle Button and Logo -->
    <div class="flex items-center w-full">
      <!-- Toggle Menu Button -->
      <button class="animated-hamburger" @click="toggleSidebar">
        <span></span>
        <span></span>
        <span></span>
      </button>

      <!-- Logo -->
      <router-link to="/" :class="['flex items-center', desktopClass]">
        <img src="/logo/logo-ufafat.png" alt="UFAFAT Logo" class="h-10" />
      </router-link>
    </div>

    <!-- Desktop Menu -->
    <div v-if="!isSidebarOpen && isDesktop" class="hidden md:flex space-x-4">
      <router-link
        to="/register"
        class="neon-button min-w-[120px] py-2 bg-black border border-white rounded-full text-center"
      >
        สมัครสมาชิก
      </router-link>
      <router-link
        to="/login"
        class="neon-button min-w-[120px] py-2 bg-black border border-white rounded-full text-center"
      >
        เข้าสู่ระบบ
      </router-link>
    </div>

    <!-- Sidebar with Click-to-Close Background -->
    <transition name="sidebar">
      <div
        v-if="isSidebarOpen"
        class="fixed inset-0 bg-gray-20 bg-opacity-20 z-50 flex justify-start"
        @click="closeSidebar"
      >
        <!-- Sidebar Content -->
        <div
          class="relative w-64 h-full bg-black shadow-lg text-white"
          @click.stop
        >
          <Sidebar @close="closeSidebar" />
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";

export default {
  name: "AppNavbar",
  components: {
    Sidebar,
  },
  data() {
    return {
      isSidebarOpen: false,
      isDesktop: window.innerWidth >= 768,
    };
  },
  computed: {
    desktopClass() {
      return this.isDesktop ? "ml-4" : "ml-auto";
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
    updateWindowWidth() {
      this.isDesktop = window.innerWidth >= 768;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style scoped>
/* Hamburger Menu Styles */
/* Hamburger Menu Styles */
.animated-hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 35px;
  height: 26px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.animated-hamburger span {
  display: block;
  width: 100%;
  height: 4px;
  background-color: white;
  border-radius: 2px;
  animation: hamburger-animate 2s infinite ease-in-out;
}

.animated-hamburger span:nth-child(1) {
  animation: hamburger-animate-short 1.5s infinite ease-in-out;
  animation-delay: 0s; /* First line starts immediately */
}

.animated-hamburger span:nth-child(2) {
  animation: hamburger-animate-long 1.5s infinite ease-in-out;
  animation-delay: 0.2s; /* Second line starts with a delay */
}

.animated-hamburger span:nth-child(3) {
  animation: hamburger-animate-short-reverse 1.5s infinite ease-in-out;
  animation-delay: 0.4s; /* Third line starts with a greater delay */
}

/* Keyframe Animations */
@keyframes hamburger-animate-short {
  0%, 100% {
    width: 80%; /* Short line */
    transform: translateY(0); /* No vertical movement */
  }
  50% {
    width: 90%; /* Slightly longer */
    transform: translateY(0); /* No vertical movement */
  }
}

@keyframes hamburger-animate-long {
  0%, 100% {
    width: 100%; /* Full width */
    transform: translateY(0); /* No vertical movement */
  }
  50% {
    width: 80%; /* Slightly shorter */
    transform: translateY(0); /* No vertical movement */
  }
}

@keyframes hamburger-animate-short-reverse {
  0%, 100% {
    width: 60%; /* Short line */
    transform: translateY(0); /* No vertical movement */
  }
  50% {
    width: 90%; /* Slightly longer */
    transform: translateY(0); /* No vertical movement */
  }
}


/* Add transition styles */
.sidebar-enter-active,
.sidebar-leave-active {
  transition: all 0.3s ease;
}
.sidebar-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}
.sidebar-enter-to {
  transform: translateX(0);
  opacity: 1;
}
.sidebar-leave-from {
  transform: translateX(0);
  opacity: 1;
}
.sidebar-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

/* Neon Button Styles */
.neon-button {
  box-shadow: 0 0 1px red, 0 0 10px red, 0 0 15px red;
  transition: box-shadow 0.3s ease;
  animation: neon-blink 1.5s infinite alternate;
}

.neon-button:hover {
  box-shadow: 0 0 1px red, 0 0 10px red, 0 0 15px red;
}

/* Neon Blink Animation */
@keyframes neon-blink {
  0% {
    box-shadow: 0 0 5px red, 0 0 8px red, 0 0 10x red;
  }
  100% {
    box-shadow: 0 0 1px red, 0 0 7px red, 0 0 10px red;
  }
}
</style>
