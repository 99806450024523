<template>
    <div>
      <!-- Navbar -->
      <NavbarLogin />
  
      <div class="bg-black text-white min-h-screen p-4 max-w-[700px] mx-auto">
        <!-- Header -->
        <div class="bg-red-600 p-4 rounded-lg mb-4 text-center">
          <h1 class="text-2xl font-bold">Tournament Super PGSlots</h1>
          <p class="text-lg font-bold">เงินรางวัล: <span class="text-2xl text-yellow-400">฿ 10,000</span></p>
          <div v-if="isTournamentJoined" class="mt-4">
            <p class="text-sm font-bold mt-2">เวลาที่เหลือ: <span class="text-yellow-400">{{ countdown }}</span></p>
          </div>
        </div>
  
        <!-- Rules and Conditions -->
        <div class="bg-gray-900 p-4 rounded-lg mb-4">
          <h2 class="text-lg font-bold mb-2">กติกาและเงื่อนไข</h2>
          <ul class="list-disc pl-5">
            <li>ผู้ชนะจะได้รับเงินรางวัล 10,000 บาท แบ่งกันครบทีม</li>
            <li>การแข่งขันเริ่มต้นวันถัดไป เวลา 00:00 - 23:00 หลังรวมทีมครบ และ กดเข้าร่วม Tournament</li>
            <li>ทีมที่มียอดการเล่น PG Slot สูงสุดจะเป็นผู้ชนะ</li>
          </ul>
        </div>
  
        <!-- Invite Form -->
        <div class="bg-gray-800 p-4 rounded-lg mb-4">
          <h2 class="text-lg font-bold mb-2">ชวนเพื่อนเข้ากลุ่ม</h2>
          <div>
            <input
              v-model="inviteId"
              type="text"
              placeholder="กรอก ID เพื่อน"
              class="w-full p-2 rounded-lg bg-gray-700 text-white mb-2"
            />
            <button
              @click="inviteMember"
              :disabled="team.length >= 3"
              class="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
            >
              เชิญเข้ากลุ่ม
            </button>
          </div>
          <p v-if="team.length >= 3" class="text-sm text-red-400 mt-2">
            ทีมของคุณเต็มแล้ว (สูงสุด 3 คน)
          </p>
        </div>
  
        <!-- Pending Invitations -->
        <div class="bg-gray-900 p-4 rounded-lg mb-4" v-if="pendingInvitations.length">
          <h2 class="text-lg font-bold mb-2">คำเชิญที่รอการตอบรับ</h2>
          <ul>
            <li
              v-for="(invitation, index) in pendingInvitations"
              :key="index"
              class="flex justify-between items-center bg-gray-800 p-2 rounded-lg mb-2"
            >
              <p>{{ invitation.name }} <span class="text-yellow-400">(รอยืนยัน)</span></p>
              <div class="space-x-2">
                <button
                  @click="acceptInvite(index)"
                  class="bg-green-600 text-white px-2 py-1 rounded-lg hover:bg-green-700"
                >
                  ยืนยัน
                </button>
                <button
                  @click="cancelInvite(index)"
                  class="bg-red-600 text-white px-2 py-1 rounded-lg hover:bg-red-700"
                >
                  ยกเลิก
                </button>
              </div>
            </li>
          </ul>
        </div>
  
        <!-- Team Members -->
        <div class="bg-gray-800 p-4 rounded-lg mb-4">
          <h2 class="text-lg font-bold mb-2">สมาชิกในทีม</h2>
          <ul>
            <li
              v-for="(member, index) in team"
              :key="index"
              class="flex justify-between items-center bg-gray-900 p-2 rounded-lg mb-2"
            >
              <div>
                <p>{{ member.name }} (ID: {{ member.id }})</p>
                <p class="text-sm text-gray-400">ระดับ: {{ member.role }}</p>
              </div>
              <p class="text-yellow-400">ยอดเล่น: ฿ {{ member.score }}</p>
            </li>
          </ul>
          <p class="text-lg font-bold text-yellow-400 mt-4">
            ยอดรวมทีม: ฿ {{ totalTeamScore }}
          </p>
        </div>
  
        <!-- Opponent Team -->
        <div v-if="isTournamentJoined" class="bg-gray-900 p-4 rounded-lg mb-20">
          <h2 class="text-lg font-bold mb-2">
            ทีมคู่แข่ง : <span class="font-bold text-yellow-400">{{ finalOpponentName }}</span>
          </h2>
          <p v-if="isSearchingOpponent" class="text-yellow-400 text-center animate-pulse">
            {{ currentRandomOpponent }}
          </p>
          <p v-else class="text-yellow-400 text-xl">
            ยอดรวม: ฿ {{ opponentScore }}
          </p>
        </div>
  
        <!-- Join Tournament Button -->
        <div class="text-center mb-20" v-if="team.length === 3 && !isTournamentJoined">
          <button
            @click="joinTournament"
            class="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
          >
            เข้าร่วม Tournament
          </button>
        </div>
      </div>
  
      <!-- Bottom Menu -->
      <BottomMenuLogin />
    </div>
  </template>
  
  <script>
  import NavbarLogin from "@/components/NavbarLogin.vue";
  import BottomMenuLogin from "@/components/BottomMenuLogin.vue";
  
  export default {
    name: "TournamentFight",
    components: {
      NavbarLogin,
      BottomMenuLogin,
    },
    data() {
      return {
        selfId: "USER123",
        inviteId: "",
        pendingInvitations: [],
        team: [
          { id: "USER123", name: "คุณ", score: Math.floor(Math.random() * 1000), role: "หัวหน้าทีม" },
        ],
        opponentScore: 0,
        isSearchingOpponent: false,
        isTournamentJoined: false,
        currentRandomOpponent: "",
        finalOpponentName: "",
        randomOpponentNames: ["ทีมเสือดาว", "ทีมราชสีห์", "ทีมเหยี่ยวไฟ", "ทีมมังกรทอง"],
        countdown: "24:00:00",
      };
    },
    computed: {
      totalTeamScore() {
        return this.team.reduce((total, member) => total + member.score, 0);
      },
    },
    methods: {
      inviteMember() {
        if (this.inviteId && this.team.length < 3) {
          this.pendingInvitations.push({
            id: this.inviteId,
            name: `${this.inviteId}`,
          });
          this.inviteId = "";
        }
      },
      acceptInvite(index) {
        const member = this.pendingInvitations.splice(index, 1)[0];
        this.team.push({
          ...member,
          score: Math.floor(Math.random() * 1000),
          role: "เพื่อนร่วมทีม",
        });
      },
      cancelInvite(index) {
        this.pendingInvitations.splice(index, 1);
      },
      joinTournament() {
        this.isTournamentJoined = true;
        this.isSearchingOpponent = true;
  
        const interval = setInterval(() => {
          this.currentRandomOpponent =
            this.randomOpponentNames[
              Math.floor(Math.random() * this.randomOpponentNames.length)
            ];
        }, 1000);
  
        setTimeout(() => {
          clearInterval(interval);
          this.isSearchingOpponent = false;
          this.finalOpponentName = this.currentRandomOpponent;
          this.opponentScore = Math.floor(Math.random() * 5000 + 5000);
          this.startCountdown();
        }, 5000);
      },
      startCountdown() {
        let duration = 24 * 60 * 60;
        const interval = setInterval(() => {
          if (duration <= 0) {
            clearInterval(interval);
            this.countdown = "หมดเวลา";
          } else {
            duration -= 1;
            const hours = String(Math.floor(duration / 3600)).padStart(2, "0");
            const minutes = String(Math.floor((duration % 3600) / 60)).padStart(2, "0");
            const seconds = String(duration % 60).padStart(2, "0");
            this.countdown = `${hours}:${minutes}:${seconds}`;
          }
        }, 1000);
      },
    },
  };
  </script>
  
  <style scoped>
  .bg-gray-800 {
    background-color: #1a1a1a;
  }
  
  .bg-gray-900 {
    background-color: #2a2a2a;
  }
  
  .text-yellow-400 {
    color: #facc15;
  }
  
  .text-red-600 {
    color: #dc2626;
  }
  
  .animate-pulse {
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  </style>
  