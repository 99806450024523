<template>
    <div class="bg-black max-w-[700px] mx-auto md:px-16 ">
      <!-- Navbar -->
      <NavbarLogin />
  
      <!-- Main Content -->
      <div class="flex flex-grow items-center justify-center">
        <div class="w-[400px] p-6 rounded-lg shadow-lg bg-gradient-to-b from-gray-900 to-black">
          <!-- Title -->
          <h2 class="text-center text-2xl font-bold text-red-600 neon-text mb-6">คืนยอดเสีย</h2>
  
          <!-- Refund Amount -->
          <div class="bg-gray-800 p-4 rounded-lg flex items-center justify-between mb-6">
            <div class="flex items-center">
              <i class="fas fa-money-bill-wave text-yellow-400 text-3xl mr-4"></i>
              <div>
                <p class="text-lg font-bold text-white">รายได้คืนยอดเสีย</p>
                <p class="text-2xl font-bold text-yellow-400">฿ 1,250</p>
              </div>
            </div>
          </div>
  
          <!-- Action Button -->
          <button
            @click="claimRefund"
            class="w-full text-white py-3 bg-red-600 hover:bg-red-700 rounded font-bold text-lg flex items-center justify-center"
          >
            <i class="fas fa-hand-holding-usd mr-2 text-white"></i> รับรายได้คืนยอดเสีย
          </button>
  
          <!-- Warning Section -->
          <div class="mt-6 bg-red-100 p-3 rounded-md flex items-center">
            <i class="fas fa-exclamation-triangle text-red-600 text-xl mr-3"></i>
            <p class="text-gray-800 text-sm">
              ลูกค้ารับยอดเสียได้ทุกสัปดาห์<br />
              <span class="font-bold">(**ระบบจะคำนวนคืนยอดเสียให้ทุกวันจันทร์**)</span>
            </p>
          </div>
        </div>
      </div>
  
      <!-- Footer -->
      <Footer />
  
      <!-- Bottom Menu -->
      <BottomMenuLogin />
    </div>
  </template>
  
  <script>
  import NavbarLogin from "@/components/NavbarLogin.vue";
  import Footer from "@/components/Footer.vue";
  import BottomMenuLogin from "@/components/BottomMenuLogin.vue";
  import { useToast } from "vue-toastification";
  
  export default {
    name: "RefundPage",
    components: {
      NavbarLogin,
      Footer,
      BottomMenuLogin,
    },
    setup() {
      const toast = useToast();
  
      const claimRefund = () => {
        toast.success("รับรายได้คืนยอดเสียสำเร็จ!");
        // Logic for claiming the refund can be added here.
      };
  
      return {
        claimRefund,
      };
    },
  };
  </script>
  
  <style scoped>
  .bg-gradient-to-b {
    background: linear-gradient(to bottom, #111, #000);
  }
  
  .neon-text {
    text-shadow: 
      0 0 5px #fff, 
      0 0 10px #fff, 
      0 0 20px #fff, 
      0 0 30px #ff0000, 
      0 0 40px #ff0000, 
      0 0 50px #ff0000, 
      0 0 75px #ff0000;
    animation: neon-blink 1.5s infinite alternate;
  }
  
  @keyframes neon-blink {
    0% {
      text-shadow: 
        0 0 5px #fff, 
        0 0 10px #fff, 
        0 0 20px #fff, 
        0 0 30px #ff0000, 
        0 0 40px #ff0000, 
        0 0 50px #ff0000, 
        0 0 75px #ff0000;
    }
    100% {
      text-shadow: 
        0 0 2px #fff, 
        0 0 5px #fff, 
        0 0 10px #ff0000, 
        0 0 20px #ff0000, 
        0 0 30px #ff0000, 
        0 0 40px #ff0000, 
        0 0 50px #ff0000;
    }
  }
  </style>
  