<template>
  <div class="max-w-[700px] mx-auto fixed bottom-0 left-0 right-0 bg-red-800 shadow-lg z-10">
    <div class="grid grid-cols-4 text-center divide-x divide-gray-500 text-white">
      <!-- Internal Routes -->
      <router-link
        v-for="(item, index) in internalMenuItems"
        :key="index"
        :to="item.route"
        class="menu-item"
      >
        <i :class="item.icon" class="text-2xl mb-1"></i>
        <p class="text-sm font-bold">{{ item.label }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomMenuLogin",
  data() {
    return {
      menuItems: [
        { label: "เล่นเกม", icon: "fas fa-gamepad", route: "/playgame", isExternal: false },
        { label: "ฝากเงิน", icon: "fas fa-money-bill-trend-up", route: "/deposit", isExternal: false },
        { label: "ถอนเงิน", icon: "fas fa-money-bill-transfer", route: "/withdraw", isExternal: false },
        { label: "คืนยอดเสีย", icon: "fas fa-hand-holding-dollar", route: "/refund", isExternal: false },
      ],
    }; 
  },
  computed: {
    internalMenuItems() {
      return this.menuItems.filter((item) => !item.isExternal);
    },
  },
};
</script>

<style scoped>
.fixed {
  background-color: #991b1b; /* Matches dark red tone */
}

.shadow-lg {
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
}

/* Shared styles for router-link and a tags */
.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.menu-item:hover {
  background-color: #c53030; /* Hover state red */
  transform: scale(1.05); /* Slight zoom effect */
}

.menu-item:active {
  background-color: #a10d0d; /* Darker red on active state */
  transform: scale(0.95); /* Slight shrink effect */
}
</style>
