<template>
    <div class="bg-black max-w-[700px] mx-auto md:px-16">
      <!-- Navbar -->
      <NavbarLogin />
  
      <!-- Account Information Section -->
      <div class="max-w-[500px] ml-3 mr-3 mx-auto bg-white mt-6 p-6 rounded-lg shadow-md">
        <h2 class="text-center text-xl font-bold text-gray-800 mb-4">ข้อมูลบัญชี</h2>
        
        <!-- Account Info -->
        <div class="space-y-4">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <i class="fas fa-id-card text-gray-600 mr-2"></i>
              <span class="text-gray-600 font-medium">ID ผู้ใช้งาน:</span>
            </div>
            <span class="text-gray-800 font-bold">นาย ชวนดี ตีสรักมิติ</span>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <i class="fas fa-user text-gray-600 mr-2"></i>
              <span class="text-gray-600 font-medium">ชื่อบัญชีธนาคาร:</span>
            </div>
            <span class="text-gray-800 font-bold">นาย ชวนดี ตีสรักมิติ</span>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <i class="fas fa-university text-gray-600 mr-2"></i>
              <span class="text-gray-600 font-medium">ชื่อธนาคาร:</span>
            </div>
            <span class="text-gray-800 font-bold">ธนาคารกสิกรไทย</span>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <i class="fas fa-credit-card text-gray-600 mr-2"></i>
              <span class="text-gray-600 font-medium">เลขบัญชีธนาคาร:</span>
            </div>
            <span class="text-gray-800 font-bold">4912020172</span>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <i class="fas fa-lock text-gray-600 mr-2"></i>
              <span class="text-gray-600 font-medium">รหัสผ่าน:</span>
            </div>
            <div class="flex items-center">
              <span class="text-gray-800 font-bold">{{ showPassword ? password : '********' }}</span>
              <button @click="togglePassword" class="ml-2 text-gray-600 hover:text-gray-800">
                <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Footer -->
      <Footer />
  
      <!-- Bottom Menu -->
      <BottomMenuLogin />
    </div>
  </template>
  
  <script>
  import NavbarLogin from "@/components/NavbarLogin.vue";
  import Footer from "@/components/Footer.vue";
  import BottomMenuLogin from "@/components/BottomMenuLogin.vue";
  import { ref } from "vue";
  
  export default {
    name: "AccountInfo",
    components: {
      NavbarLogin,
      Footer,
      BottomMenuLogin,
    },
    setup() {
      const password = ref("132456479");
      const showPassword = ref(false);
  
      const togglePassword = () => {
        showPassword.value = !showPassword.value;
      };
  
      return {
        password,
        showPassword,
        togglePassword,
      };
    },
  };
  </script>
  
  <style scoped>
  .bg-gray-100 {
    background-color: #f5f5f5;
  }
  
  button {
    transition: background-color 0.3s ease;
  }
  </style>
  