<template>
  <div class="fixed inset-0 bg-black bg-opacity-80 z-100 flex text-white">
    <!-- Sidebar -->
    <div class="w-2/3 h-full bg-black flex flex-col">
      <!-- Logo and Close Button -->
      <div class="flex items-center justify-between bg-red-600 p-4">
        <div class="flex items-center space-x-2">
          <img src="/logo/logo-ufafat.png" alt="UFAFAT Logo" class="h-10" />
        </div>
        <button @click="closeSidebar"
          class="text-white text-3xl bg-black rounded-full w-6 h-6 flex items-center justify-center shadow-lg hover:shadow-xl">
          &times;
        </button>
      </div>

      <!-- Menu Items -->
      <div class="flex-1 overflow-y-auto">
        <ul class="space-y-2 p-4">
          <li
            v-for="(item, index) in menuItems"
            :key="item.label"
            :class="['menu-item', index === 0 ? 'hovered' : '']"
          >
            <a :href="item.path" class="flex items-center w-full">
              <span class="icon">
                <i :class="item.icon"></i>
              </span>
              <span class="text">{{ item.label }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSidebar",
  data() {
    return {
      menuItems: [
        { label: "หน้าแรก", icon: "fas fa-home", path: "/" },
        { label: "ข้อมูลบัญชี", icon: "fas fa-user", path: "/account-info" },
        { label: "ประวัติ", icon: "fas fa-list", path: "/history" },
        { label: "แนะนำเพื่อน", icon: "fas fa-users", path: "/refer-friends" },
        { label: "โบนัสฝากแรกรับเพี่ม", icon: "fas fa-star", path: "/bonus" },
      ],
    };
  },
  methods: {
    closeSidebar() {
      this.$emit("close"); // ส่ง event ปิดกลับไป
    },
  },
};
</script>

<style scoped>
/* General Menu Items Styling */
.menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #000;
  transition: background-color 0.2s;
}

.menu-item:hover,
.menu-item.hovered {
  background-color: #dc2626;
}

.icon {
  flex-shrink: 0;
  width: 30px;
  text-align: center;
}

.text {
  flex-grow: 1;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

button.text-white {
  transition: background-color 0.3s ease;
}

button.text-white:hover {
  background-color: #333;
}
</style>
