<template>
    <div class="max-w-[700px] mx-auto fixed bottom-0 left-0 right-0 bg-red-800 shadow-lg z-10">
      <div
        class="grid grid-cols-4 text-center divide-x divide-gray-500 text-white"
      >
        <!-- Menu Items -->
        <router-link
          v-for="(item, index) in menuItems"
          :key="index"
          :to="item.route"
          class="flex flex-col items-center justify-center py-2 hover:bg-red-700"
        >
          <i :class="item.icon" class="text-2xl mb-1"></i>
          <p class="text-sm font-bold">{{ item.label }}</p>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "BottomMenuApp",
    data() {
      return {
        menuItems: [
          { label: "เข้าสู่ระบบ", icon: "fas fa-sign-in-alt", route: "/login" },
          { label: "สมัครสมาชิก", icon: "fas fa-user-plus", route: "/register" },
          { label: "ฝากเงิน", icon: "fas fa-wallet", route: "/deposit" },
          { label: "สร้างรายได้", icon: "fas fa-dollar-sign", route: "/earn" },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .fixed {
    background-color: #991b1b; /* Matches dark red tone */
  }
  .shadow-lg {
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  }
  .grid > .hover\:bg-red-700:hover {
    background-color: #c53030; /* Hover state red */
  }
  </style>
  