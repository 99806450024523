<template>
  <NavbarLogin />

  <div class="bg-black text-white min-h-screen p-4 max-w-[700px] mx-auto">
    <!-- Jackpot Header -->
    <div class="relative bg-red-600 p-4 rounded-lg mb-4 overflow-hidden">
      <h1 class="text-2xl font-bold text-center relative z-10">Mega Jackpot แจกรายวัน</h1>
      <p class="text-4xl font-bold text-yellow-400 text-center mt-2 relative z-10">
        ฿ <span id="jackpot-amount"></span>
      </p>
      <p class="text-center text-sm text-gray-200 mt-2 relative z-10">
        แจ็คพอตแตกเวลา 23:00 น. คนที่ฝาก ขั้นต่ำ 1,000บ ก็มีสิทธิ์ลุ้นได้
      </p>
    </div>

    <!-- Player Status -->
    <div class="bg-gray-800 p-4 rounded-lg mb-4">
      <!-- ใช้ Flexbox แบ่งข้อมูลออกเป็นสองคอลัมน์ -->
      <div class="flex justify-between items-center">
        <!-- คอลัมน์ซ้าย -->
        <div>
          <p class="text-sm flex items-center">
            <i class="fas fa-ticket-alt text-yellow-400 mr-2"></i>
            สิทธิ์สะสมทั้งหมด:
            <span class="text-yellow-400 ml-1">{{ totalTickets }} สิทธิ์</span>
          </p>
          <p class="text-sm flex items-center mt-2">
            <i class="fas fa-level-up-alt text-green-500 mr-2"></i>
            ระดับภารกิจ:
            <span class="text-green-500 ml-1">{{ currentLevel }}</span>
          </p>
        </div>

        <!-- เส้นแบ่ง -->
    <div class="w-px bg-gray-600 h-12"></div>

        <!-- คอลัมน์ขวา -->
        <div class="text-center flex flex-col items-center">
          <p class="text-sm font-bold flex items-center mb-1">
            <i class="fas fa-coins text-yellow-400 mr-2"></i>
            ยอดฝากวันนี้:
          </p>
          <div class="flex items-center">
            <p class="text-lg text-yellow-400">฿ {{ todayDeposit }}</p>
            <button @click="refreshDeposit" class="ml-2">
              <i class="fas fa-sync-alt text-white hover:text-yellow-400"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Mission Section -->
    <div class="bg-gray-900 p-4 rounded-lg mb-20">
      <h2 class="text-xl font-bold mb-4">รายการภารกิจ</h2>

      <div v-for="(level, index) in missions" :key="index" class="mb-6">
        <h3 class="text-lg font-bold text-yellow-400">{{ level.label }}</h3>

        <ul class="space-y-4">
          <li v-for="(task, idx) in level.tasks" :key="idx" class="bg-gray-800 p-4 rounded-lg shadow-md">
            <div class="flex justify-between items-center mb-2">
              <p class="text-sm">{{ task.name }}</p>
              <p class="text-sm text-yellow-400">+{{ task.tickets }} สิทธิ์</p>
            </div>
            <div>
              <div class="w-full bg-gray-700 rounded-full h-2 mb-1">
                <div class="bg-red-600 h-2 rounded-full" :style="{ width: task.progress + '%' }"></div>
              </div>
              <p class="text-xs text-gray-400">{{ task.progress }}% คืบหน้า</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Bottom Menu -->
  <BottomMenuLogin />
</template>

<script>
import NavbarLogin from "@/components/NavbarLogin.vue";
import BottomMenuLogin from "@/components/BottomMenuLogin.vue";
import { CountUp } from "countup.js";

export default {
  name: "MegaJackpot",
  components: {
    NavbarLogin,
    BottomMenuLogin,
  },
  data() {
    return {
      totalTickets: 0,
      jackpotAmount: 100000, // Initial amount
      currentLevel: "Basic",
      todayDeposit: 1500, // เพิ่มยอดฝากวันนี้ของผู้ใช้
      nextLevel: "Intermediate",
      missions: [
  {
    label: "เริ่มต้น (Basic)",
    tasks: [
      {
        name: "ฝากเงินครั้งแรก 1,000 บาท",
        tickets: 5,
        requiredDeposit: 1000, // เกณฑ์เงินฝาก
        progress: 0,
      },
    ],
  },
  {
    label: "นักสะสม (Intermediate)",
    tasks: [
      {
        name: "ฝากเงินสะสม 5,000 บาท",
        tickets: 15,
        requiredDeposit: 5000, // เกณฑ์เงินฝาก
        progress: 0,
      },
    ],
  },
  {
    label: "ผู้เชี่ยวชาญ (Advanced)",
    tasks: [
      {
        name: "ฝากเงินสะสม 10,000 บาท",
        tickets: 30,
        requiredDeposit: 10000, // เกณฑ์เงินฝาก
        progress: 0,
      },
    ],
  },
  { 
    label: "ตำนาน (Elite)",
    tasks: [
      {
        name: "ฝากเงินสะสม 50,000 บาท",
        tickets: 50,
        requiredDeposit: 50000, // เกณฑ์เงินฝาก
        progress: 0,
      },
    ],
  },
],
    };
  },
  mounted() {
    this.initCountUp();
    this.updateMissionProgress(); // อัปเดต progress
    this.startIncrementingJackpot();
  },
  methods: {
    initCountUp() {
      // Initialize CountUp.js for jackpot amount
      const countUp = new CountUp("jackpot-amount", this.jackpotAmount, {
        startVal: this.jackpotAmount,
        duration: 10,
        separator: ",",
      });
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    },

    startIncrementingJackpot() {
      const incrementInterval = 1000; // เพิ่มทุก 1 วินาที
      const incrementAmount = 50; // เพิ่มครั้งละ 50

      const endTime = new Date();
      endTime.setHours(22, 0, 0, 0); // ตั้งเวลาให้เป็น 22:00:00

      this.incrementIntervalId = setInterval(() => {
        const now = new Date();

        if (now >= endTime) {
          // หยุดเมื่อถึงเวลา 22:00
          clearInterval(this.incrementIntervalId);
          this.jackpotAmount = Math.round(this.jackpotAmount);
        } else {
          this.jackpotAmount += incrementAmount;
        }
      }, incrementInterval);
    },

    updateMissionProgress() {
      // คำนวณ progress ของแต่ละ mission ตาม todayDeposit
      this.missions.forEach((mission) => {
        mission.tasks.forEach((task) => {
          task.progress = Math.min(
            (this.todayDeposit / task.requiredDeposit) * 100,
            100
          ); // จำกัดค่าไม่เกิน 100%
        });
      });
    },
  },
};
</script>



<style scoped>
.bg-gray-900 {
  background-color: #1a1a1a;
}

.bg-gray-800 {
  background-color: #2a2a2a;
}

.bg-gray-700 {
  background-color: #3a3a3a;
}

/* Styles for icons */
.text-yellow-400 {
  color: #fbbf24;
}

.text-green-500 {
  color: #10b981;
}

.mr-2 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}
</style>
