<template>
    <div class="bg-black max-w-[700px] mx-auto md:px-16">
        <!-- Navbar -->
        <NavbarLogin />

        <!-- Slider -->
        <div class="mb-8">
            <Slider />
        </div>

        <!-- Deposit Section -->
        <div class="flex justify-center items-center bg-black">
            <div class="bg-white ml-3 mr-3 w-[380px] md:w-[650px] p-4 rounded-lg shadow-lg">
                <div class="mb-6 -mt-4 text-center">
                    <h2 class="bg-red-600 text-white text-xl font-bold py-2 rounded-t -mx-4 px-4">ฝากเงิน</h2>
                    <p class="text-center text-black text-sm mt-2">
                        เพื่อเติมเครดิตเข้าเล่นคาสิโนออนไลน์
                    </p>
                </div>

                <!-- Bank Details -->
                <div class="mb-4">
                    <h3 class="text-lg font-bold text-gray-800">รายละเอียดบัญชีธนาคาร</h3>
                    <div class="p-4 bg-gray-100 rounded-md">
                        <p class="text-sm font-medium text-gray-700 mb-2">
                            <i class="fas fa-user-circle mr-2"></i> ชื่อบัญชี: <span class="font-bold">นายตัวอย่าง
                                ผู้ใช้งาน</span>
                        </p>
                        <p class="text-sm font-medium text-gray-700 mb-2">
                            <i class="fas fa-university mr-2"></i> ธนาคาร: <span class="font-bold">ธนาคารกสิกรไทย</span>
                        </p>
                        <p class="text-sm font-medium text-gray-700 mb-2 flex items-center">
                            <i class="fas fa-credit-card mr-2"></i> เลขบัญชี:<span class="font-bold">
                                123-456-7890</span>
                            <button @click="copyToClipboard('123-456-7890')"
                                class="inline-flex items-center text-blue-600 hover:text-blue-800 text-sm ml-2 whitespace-nowrap">
                                <i class="fas fa-copy mr-1"></i> คัดลอก
                            </button>
                        </p>
                    </div>
                </div>

                <!-- Upload Slip -->
                <div class="mb-4">
                    <h3 class="text-lg font-bold text-gray-800">อัปโหลดสลิปโอนเงิน</h3>
                    <input type="file" accept="image/*" @change="handleFileUpload"
                        class="w-full border p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500" />
                </div>

                <!-- Submit Button -->
                <button @click="uploadSlip"
                    class="w-full bg-red-600 text-white py-3 rounded font-bold text-lg hover:bg-red-700 flex items-center justify-center">
                    <i class="fas fa-upload mr-2"></i> อัปโหลดสลิป
                </button>

                <!-- Warning Message -->
                <p class="bg-red-900 text-xs text-white mt-4 p-3 rounded-md flex items-center">
                    <i class="fas fa-exclamation-triangle text-yellow-400 mr-2"></i>
                    ชื่อบัญชีฝากต้องตรงกับบัญชีถอน ไม่เช่นนั้นจะถอนเงินไม่ได้
                    (ระบบจะจับคู่ยอดเงินเครดิตเข้าบัญชีท่านอัตโนมัติ)
                    <br />
                </p>
            </div>
        </div>

        <!-- Footer -->
        <Footer />

        <!-- Bottom Menu -->
        <BottomMenuLogin />
    </div>
</template>

<script>
import NavbarLogin from "@/components/NavbarLogin.vue";
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";
import { useToast } from "vue-toastification";
import { ref } from "vue";
import BottomMenuLogin from "@/components/BottomMenuLogin.vue";

export default {
    name: "DepositPage",
    components: {
        NavbarLogin,
        Slider,
        Footer,
        BottomMenuLogin,
    },
    setup() {
        const toast = useToast();
        const slipFile = ref(null);

        const copyToClipboard = (text) => {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(text)
                    .then(() => {
                        toast.success("คัดลอกเลขบัญชีเรียบร้อยแล้ว!");
                    })
                    .catch(() => {
                        toast.error("ไม่สามารถคัดลอกได้ ลองอีกครั้ง");
                    });
            } else {
                fallbackCopyText(text);
            }
        };

        const fallbackCopyText = (text) => {
            const textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.position = "fixed"; // ป้องกันการเลื่อน
            textArea.style.opacity = 0; // ซ่อน
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand("copy");
                if (successful) {
                    toast.success("คัดลอกเลขบัญชีเรียบร้อยแล้ว!");
                } else {
                    toast.error("ไม่สามารถคัดลอกได้ ลองอีกครั้ง");
                }
            } catch (err) {
                toast.error("เบราว์เซอร์ของคุณไม่รองรับการคัดลอก");
            }
            document.body.removeChild(textArea);
        };

        const handleFileUpload = (event) => {
            const file = event.target.files[0];
            if (file) {
                slipFile.value = file;
                toast.success("เลือกไฟล์สลิปเรียบร้อยแล้ว!");
            } else {
                toast.error("กรุณาเลือกไฟล์สลิปอีกครั้ง");
            }
        };

        const uploadSlip = () => {
            if (!slipFile.value) {
                toast.error("กรุณาอัปโหลดสลิปก่อนดำเนินการ");
                return;
            }

            // Simulate slip upload
            toast.success("อัปโหลดสลิปสำเร็จ!");
        };

        return {
            copyToClipboard,
            handleFileUpload,
            uploadSlip,
        };
    },
};
</script>

<style scoped>
.bg-black {
    background-color: #000;
}

input[type="file"] {
    border: 1px solid #ccc;
    padding: 10px;
}

.bg-red-900 {
    background-color: #b91c1c;
}
</style>