import axios from 'axios';

const isLocalhost = window.location.hostname === 'localhost';

const baseURL = isLocalhost
  ? 'http://localhost:5007'
  : process.env.VUE_APP_API_BASE;

const api = axios.create({
  baseURL,
  withCredentials: false,
});

export default api;
