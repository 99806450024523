<template>
  <div class="bg-black max-w-[700px] mx-auto md:px-16">

    <!-- Navbar -->
    <NavbarLogin />

    <!-- Referral Section -->
    <div class="max-w-[500px] ml-3 mr-3 mx-auto bg-white mt-6 p-1 rounded-lg shadow-md">
      <!-- Title -->
      <h2 class="text-center text-xl font-bold text-gray-800 mb-4">แนะนำเพื่อน</h2>

      <div class="bg-gray-800 p-4 rounded-lg flex items-center justify-between mb-1">
        <!-- ยอดเครดิตแนะนำเพื่อน -->
        <div class="flex-1 flex items-center justify-center border-r border-gray-600 pr-4">
          <div>
            <p class="text-md font-bold text-white text-center">ยอดเครดิตแนะนำเพื่อน</p>
            <p class="text-2xl font-bold text-yellow-400 text-center">฿ 10,250</p>
          </div>
        </div>

        <!-- เพื่อนของคุณ -->
        <div class="flex-1 flex items-center justify-center pl-4">
          <div>
            <p class="text-md font-bold text-white text-center">เพื่อนของคุณ</p>
            <p class="text-2xl font-bold text-yellow-400 text-center">50</p>
            <p class="text-lg font-bold text-white text-center">คน</p>
          </div>
        </div>
      </div>



      <!-- Referral Link -->
      <div class="bg-gray-50 p-4 rounded-md shadow-sm">
        <h3 class="text-lg font-bold text-gray-700 mb-2">ลิงค์แนะนำเพื่อน</h3>
        <div class="flex items-center">
          <input type="text" v-model="referralLink" readonly
            class="flex-1 bg-gray-100 border border-gray-300 rounded-l-md p-2 text-sm text-gray-700 focus:outline-none" />
          <button @click="copyReferralLink"
            class="bg-red-600 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded-r-md">
            คัดลอก
          </button>
        </div>
        <p class="text-xs text-gray-500 mt-2">แชร์ลิงค์นี้ให้เพื่อนของคุณเพื่อรับโบนัสพิเศษ!</p>

      </div>
      <!-- Conditions -->
      <div class="bg-gray-50 p-4 rounded-md shadow-sm mb-6">
        <h3 class="text-lg font-bold text-gray-700 mb-2">เงื่อนไขการแนะนำเพื่อน</h3>
        <ul class="list-disc pl-6 text-sm text-gray-600">
          <li>รับโบนัสทันทีเมื่อเพื่อนของคุณสมัครและฝากเงินขั้นต่ำ 500 บาท</li>
          <li>โบนัสจะถูกเพิ่มเข้าบัญชีของคุณทันทีที่เพื่อนทำรายการสำเร็จ</li>
          <li>สิทธิ์แนะนำเพื่อนไม่จำกัด ยิ่งชวนเยอะยิ่งได้เยอะ</li>
        </ul>
      </div>
    </div>

    <!-- Footer -->
    <Footer />

    <!-- Bottom Menu -->
    <BottomMenuLogin />
  </div>
</template>

<script>
import NavbarLogin from "@/components/NavbarLogin.vue";
import Footer from "@/components/Footer.vue";
import BottomMenuLogin from "@/components/BottomMenuLogin.vue";
import { useToast } from "vue-toastification";
import { ref } from "vue";

export default {
  name: "ReferFriendsPage",
  components: {
    NavbarLogin,
    Footer,
    BottomMenuLogin,
  },
  setup() {
    const toast = useToast();
    const referralLink = ref("http://localhost:8080/register?referralcode=ABC123");

    const copyReferralLink = () => {
      navigator.clipboard
        .writeText(referralLink.value)
        .then(() => {
          toast.success("คัดลอกลิงค์เรียบร้อยแล้ว!");
        })
        .catch(() => {
          toast.error("เกิดข้อผิดพลาดในการคัดลอกลิงค์");
        });
    };

    return {
      referralLink,
      copyReferralLink,
    };
  },
};
</script>

<style scoped>
.bg-gray-100 {
  background-color: #f5f5f5;
}

.bg-blue-600:hover {
  background-color: #f8062e;
  /* Tailwind Hover Blue */
}
</style>