<template>
    <div class="bg-black max-w-[700px] mx-auto md:px-16">
      <!-- Navbar -->
      <NavbarLogin />
  
      <!-- History Section -->
      <div class="max-w-[600px]  ml-3 mr-3 mx-auto bg-white mt-6 p-3 rounded-lg shadow-md">
        <h2 class="text-center text-xl font-bold text-gray-800 mb-4">ประวัติการทำรายการ</h2>
  
        <!-- Transaction List -->
        <div v-if="historyData.length" class="space-y-4">
          <div
            v-for="(record, index) in historyData"
            :key="index"
            class="bg-gray-100 p-2 rounded-lg shadow-sm flex justify-between items-center"
          >
            <!-- Date and Type -->
            <div>
              <p class="text-sm text-gray-600">{{ record.date }}</p>
              <p
                class="text-sm font-bold"
                :class="record.type === 'ฝาก' ? 'text-green-600' : 'text-red-600'"
              >
                {{ record.type }}
              </p>
            </div>
            <!-- Amount -->
            <div>
              <p class="text-lg font-bold text-gray-800">{{ record.amount }} เครดิต</p>
            </div>
          </div>
        </div>
  
        <!-- No History Message -->
        <div v-else class="text-center text-gray-600 mt-6">
          <i class="fas fa-info-circle text-2xl mb-2"></i>
          <p>ไม่มีประวัติการทำรายการ</p>
        </div>
      </div>
  
      <!-- Footer -->
      <Footer />
  
      <!-- Bottom Menu -->
      <BottomMenuLogin />
    </div>
  </template>
  
  <script>
  import NavbarLogin from "@/components/NavbarLogin.vue";
  import Footer from "@/components/Footer.vue";
  import BottomMenuLogin from "@/components/BottomMenuLogin.vue";
  
  export default {
    name: "HistoryPage",
    components: {
      NavbarLogin,
      Footer,
      BottomMenuLogin,
    },
    data() {
      return {
        // Sample transaction history data
        historyData: [
          { date: "23-12-2024", amount: "300", type: "ฝาก" },
          { date: "22-12-2024", amount: "500", type: "ถอน" },
          { date: "21-12-2024", amount: "1000", type: "ฝาก" },
          { date: "23-12-2024", amount: "300", type: "ฝาก" },
          { date: "22-12-2024", amount: "500", type: "ถอน" },
          { date: "21-12-2024", amount: "1000", type: "ฝาก" },
          { date: "23-12-2024", amount: "300", type: "ฝาก" },
          { date: "22-12-2024", amount: "500", type: "ถอน" },
          { date: "21-12-2024", amount: "1000", type: "ฝาก" },
          { date: "23-12-2024", amount: "300", type: "ฝาก" },
          { date: "22-12-2024", amount: "500", type: "ถอน" },
          { date: "21-12-2024", amount: "1000", type: "ฝาก" },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .bg-gray-100 {
    background-color: #f5f5f5;
  }
  
  .shadow-sm {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .shadow-md {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .text-green-600 {
    color: #16a34a; /* Green tone for deposit */
  }
  
  .text-red-600 {
    color: #dc2626; /* Red tone for withdrawal */
  }
  </style>
  